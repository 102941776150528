/* CouriersLandingPage */

.couriersHeroSection {
  background-image: url("https://dado-food-store.s3.eu-central-1.amazonaws.com/1710285178319");
  height: 954px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 80px 20px;
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 0px 0px 40px 40px;
}
.couriersHeroContent {
  padding-top: 128px;
}
.couriersHeroContent h1 {
  color: #fff;
  font-family: Amaranth;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}
.couriersHeroContent p {
  font-size: 20px;
  line-height: 30px;
  color: #e6e7e9;
  margin-bottom: 40px;
}
.courierHeroBtmImageContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 128px;
}
.couriersBackgroundLayer {
  background: #fff;
  padding: 0 5px;
}
.couriersSecondSection {
  border-radius: 40px;
  box-shadow: 4px 4px 16px 0px rgba(3, 3, 3, 0.06);
  display: flex;
  padding: 48px 12px 16px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 80px; */
  background: #011014;
  position: relative;
  top: -40px;
  width: 100%;
}
.couriersSecondSection header {
  color: rgba(230, 237, 239, 1);
  margin-bottom: 80px;
}
.couriersSecondSection header h4 {
  color: #f4743b;

  /* Body/Small/Semibold */
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;
}
.couriersSecondSection header h3 {
  color: #f9f9fb;
  font-family: Amaranth;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}
.couriersSecondSection header p {
  color: #e6edef;

  /* Body/Small/Light */
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;
}

.courierHeroGifContainer {
  height: 80px;
  width: 80px;
  border-radius: 100px;
  background: #fff;
  padding: 10px;
  position: absolute;
  top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.courierHeroGifContainer span {
  border-radius: 150px;
  border: 0.714px solid #f9f9fb;
  background: #04d9d1;
  display: flex;
  width: 57.143px;
  height: 57.143px;
  padding: 7px;
  justify-content: center;
  align-items: center;
}
.courierHeroGifContainer img {
  border-radius: 100px;
}
.courierSecondSectionContent {
  background-image: url("https://dado-food-store.s3.eu-central-1.amazonaws.com/1710267380999");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 24px;
  width: 100%;
}
.courierSecondSectionContent .riderFlow {
  width: 100%;
}
.courierSecondSectionContentFooter {
  border-radius: 16px;
  border: 0.2px solid #fef1eb;
  background: rgba(0, 138, 133, 0.05);
  margin: 0 8px 20px 8px;
  padding: 40px 10px;
}
.courierSecondSectionContentFooter header h4 {
  color: #025754;
  text-align: center;
  font-family: Amaranth;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  letter-spacing: 0.1px;
}
.courierSecondSectionContentFooter header p {
  color: #052f3a;
  text-align: center;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px; /* 133.333% */
  letter-spacing: 0.12px;
}
.riderText {
  color: #032027;
  text-align: center;
  font-family: Amaranth;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 128.571% */
  letter-spacing: 0.28px;
}
.yellowBgText {
  color: #032027;
  text-align: center;
  font-family: Rubik;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.12px;
  padding: 10px;
}
.yellowCardTwo {
  order: 1;
  /* text-align: center; */
}

.mobileRight {
  text-align: right;
}
.riderAboutSection {
  margin-top: -150px;
}

@media screen and (min-width: 768px) {
  .couriersHeroSection {
    height: 1200px;
  }

  .couriersHeroContent {
    padding-top: 184px;
  }

  .couriersHeroContent h1 {
    font-size: 80px;
    line-height: 96px;
    letter-spacing: 0.8px;
  }
  .couriersHeroContent p {
    font-size: 30px;
    line-height: 56px;
  }

  .couriersSecondSection {
    padding: 48px 40px;
    border-radius: 80px;
  }
  .couriersSecondSection header {
    padding-right: 80px;
  }
  .couriersSecondSection header h4 {
    color: #f4743b;

    /* Body/Medium/Semibold */
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.08px;
  }
  .couriersSecondSection header h3 {
    color: #e6edef;

    font-family: Amaranth;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
  }
  .couriersSecondSection header p {
    color: #e6edef;

    /* Body/Big/Light */
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px; /* 140% */
    letter-spacing: 0.1px;
  }
  .courierSecondSectionContent {
    border-radius: 40px;
  }
  .courierSecondSectionContentFooter {
    border-radius: 40px;
    margin: 0 20px 32px 20px;
    padding: 80px 160px 120px 160px;
  }
  .courierSecondSectionContentFooter header h4 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px; /* 120% */
    letter-spacing: 0.1px;
  }
  .courierSecondSectionContentFooter header p {
    font-size: 24px;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.12px;
  }
  .riderText {
    font-size: 64px;
    line-height: 80px; /* 125% */
    letter-spacing: 0.32px;
  }
  .yellowBgText {
    font-size: 20px;
    line-height: 28px; /* 133.333% */
    letter-spacing: 0.12px;
  }

  .mobileRight {
    text-align: center;
  }
}
