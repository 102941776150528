.locationContainer {
  padding: 20px;
  height: 100vh;
}
.locationContent {
  display: grid;
  grid-template-columns: 100%;
}
.locationLottie {
  width: 300px;
}
.locationGifBg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 300px;
}
.locationGifBg img {
  object-fit: contain;
  height: 300px;
}
.locationGifOverlay {
  position: absolute;
}
.locationText h5 {
  font-size: 20px;
  line-height: 32px;
  margin-top: 24px;
  color: #38383a;
}
.locationText p {
  font-size: 16px;
  line-height: 30px;
  color: #565758;
  margin-bottom: 24px;
}

.locationBtn {
  width: 50%;
  margin: 40px auto 40px auto;
  /* 
margin-top: 40px; */
}
.locationTabs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 0 auto 24px auto;
  width: 100%;
  padding: 0 20px;
}
.locationInput {
  width: 100%;
  padding: 0 20px;
}

.selectLocationTab {
  padding: 16px;
  gap: 8px;
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  align-items: center;
}
.selectLocationTabActive {
  background: #ffdfb6;
  border: 0.5px solid #ffa024;
}
.selectLocationTabInactive {
  border: 1px solid #919294;
}

@media screen and (min-width: 1024px) {
  .locationContainer {
    padding: 0;
  }
  .locationBtn {
    width: 20%;
  }
  .locationInput {
    width: 30%;
    margin: 24px auto 0 auto;
  }
  .locationTabs {
    width: 30%;
    margin: auto;
  }
}
