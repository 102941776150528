.onGoBack {
  padding-left: 20px;
}
.largeImageCardContainer {
  width: 100%;
  height: auto;
  border-radius: 8px;
  position: relative;
}
.largeImageCardContainer img {
  border-radius: 8px;
  height: 160px;
}
.largeImageCardOverlay {
  background: linear-gradient(0deg, rgba(19, 19, 19, 0.1) 67.05%, rgba(9, 8, 8, 0.5) 89.25%);
  border-radius: 8px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}
.largeImageCardOverlayClosed {
  background: linear-gradient(0deg, rgba(1, 1, 1, 0.8), rgba(1, 1, 1, 0.8)), linear-gradient(0deg, rgba(19, 19, 19, 0.1) 67.05%, rgba(9, 8, 8, 0.5) 89.25%);
  border-radius: 8px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.largeImageCardOverlay img {
  width: 26px;
  height: 26px;
}

.restaurantHomeContainer {
  padding: 20px;
  display: grid;
  grid-template-columns: 100%;
}
#cartHolder {
  display: none;
}

.restaurantInfoContainer {
  padding: 20px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 24px;
  margin-bottom: 40px;
}
.restaurantInfoContainer .infoTopContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 0.5px solid #d6d6d8;
  flex-wrap: wrap;
}

.restaurantInfoContainer .infoTopContainer h3 {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #38383a;
}
.restaurantInfoContainer .infoTopContainer span {
  font-size: 16px;
  line-height: 24px;
  color: #565758;
}
.restaurantInfoContainer .infoTopContainer div:first-child div > p {
  font-size: 14px;
  line-height: 24px;
  color: #696a6b;
  margin-bottom: 24px;
}

.infoTabsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 8px;
  width: 100%;
  height: 48px;
  background: #ebebeb;
  border-radius: 12px;
}

.restaurantInfoContainer .infoBottomContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  flex-wrap: wrap;
}
.restaurantInfoContainer .infoBottomContainer > div:nth-child(1) {
  font-size: 16px;
  line-height: 24px;
  color: #696a6b;
  margin-bottom: 16px;
}

.restaurantInfoContainer .infoBottomContainer > div:nth-child(2) div {
  font-size: 16px;
  line-height: 24px;
  color: #b1b2b4;
  justify-content: space-between;
}

.restaurantInfoContainer .infoBottomContainer img {
  margin-right: 8px;
}

.productsLargeCardContainer {
  padding: 24px 20px 20px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #e6e7e9;
  box-shadow: 12px 12px 20px rgba(211, 209, 216, 0.08);
  border-radius: 8px;
  margin-bottom: 120px;
}
.productsLargeCardContainer .productsTab {
  display: flex;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  gap: 16px;
  padding-bottom: 24px;
  border-bottom: 0.5px solid #d6d6d8;
  margin-bottom: 24px;
}

.productsLargeCardContainer h5 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #38383a;
}

.productsLargeCardContainer p {
  font-size: 14px;
  line-height: 24px;
  color: #696a6b;
}

.productsLargeCardContainer .productsTab .activeState {
  padding: 4px 8px;
  gap: 4px;
  /* width: 35px; */
  height: 32px;
  background: rgba(255, 144, 0, 0.12);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out all;
  color: #ffa024;
  /* transform: matrix(1, 0, 0, -1, 0, 0); */
}

.productsLargeCardContainer .productsTabGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px 16px;
}

.productsCardContainer {
  padding: 16px;
  border: 1px solid #f0f1f2;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.productsCardContent {
  width: 50%;
  padding-right: 10px;
}
.productsCardImage {
  width: 120px;
  height: 96px;
}
.productsCardImage img {
  width: 100%;
  object-fit: cover;
}
.productsCardContainer .productsCardContent h5 {
  font-size: 16px;
  line-height: 24px;
  color: #38383a;
}
.productsCardContainer .productsCardContent p {
  /* width: 236px; */
  font-size: 14px;
  line-height: 24px;
  color: #38383a;
}

.productsCardContainer .productsCardContent span {
  font-size: 14px;
  line-height: 24px;
  color: #ffa024;
}
.productsCardContainer img {
  width: 100%;
  height: 100%;
}

.productOrderContainer {
  border-radius: 24px 24px 0 0;
  /* border: 1px solid red; */
  position: relative;
  width: 100%;
  /* padding: 40px; */
}
.productOrderContainer .productOrderImage {
  border-radius: 24px;
}
.productOrderContainer .productOrderImage img {
  border-radius: 24px 24px 0 0;
  width: 100%;
  height: 300px;
}
.productOrderContainer .productOrderContent {
  padding: 40px 20px;
  background-color: #ffffff;
  border-radius: 0;
}

.productOrderContainer .closeOrder {
  position: absolute;
  right: 24px;
  top: 24px;
  background: #f0f1f2;
  border: 1px solid #f9f9fb;
  border-radius: 100px;
  padding: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radioBox {
  width: 16px;
  height: 16px;
  border-radius: 50px;
  border: 1px solid;
  display: flex;
  /* padding: ; */
  align-items: center;
  justify-content: center;
  /* padding: 5px; */
}
.radioBox .radioBoxInner {
  /* background-color: red; */
  height: 10px;
  width: 10px;
  border-radius: 50%;
  /* margin: 5px; */
}

#webAddToCart {
  display: none;
}

.cartCardContainer {
  background: #ffffff;
  border: 1px solid #e6e7e9;
  box-shadow: 12px 12px 20px rgba(211, 209, 216, 0.08);
  border-radius: 8px;
  padding: 20px;
  width: 100%;
}

.cartCardContainer .cartOrderListContainer {
  padding: 16px 0;
  /* border: 1px dashed #e6e7e9; */
  /* border-radius: 8px; */
}

.cartCardContainer .cartOrderListContainer > div:nth-child(1) {
  margin-bottom: 24px;
}

.cartCardContainer .cartOrderListContainer .cartCardItem {
  /* border-bottom: 0.5px solid #e6e7e9; */
  padding-bottom: 16px;
}
.cartCardContainer .cartCardOrderInfo {
  margin-top: 24px;
  border-bottom: 0.5px solid #e6e7e9;
  /* border-top: 0.5px solid #e6e7e9; */
  /* padding: 0 24px; */
}
.cartCardContainer .cartCardOrderInfo .borderBottom {
  border-bottom: 0.5px solid #e6e7e9;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.cartCardContainer .cartCardOrderInfo img {
  margin-right: 18px;
  width: 20px;
  height: 20px;
}
.cartCardContainer .cartCardOrderInfo .borderBottom > p {
  font-size: 14px;
  line-height: 24px;
  color: #38383a;
}

.cartCardContainer .cartCardOrderInfo span {
  font-size: 12px;
  line-height: 16px;
  color: #919294;
}

.cartCardContainer .cartCardOrderInfo .addBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  /* width: 39px; */
  height: 24px;
  background: #ffffff;
  border: 1px solid #ffa024;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ffa024;
  cursor: pointer;
}

.cartCardContainer .cartCardOrderInfo .billingContainer {
  margin: 24px 0 40px 0;
}
.cartCardContainer .cartCardOrderInfo .billingContainer h5 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #38383a;
  margin-bottom: 24px;
  border-bottom: 0.5px solid #e6e7e9;
  padding-bottom: 16px;
}

.cartCardContainer .cartCardOrderInfo .billingContainer .pricesContainer img {
  width: 20px;
  height: 20px;
}
.cartCardContainer .cartCardOrderInfo .billingContainer .pricesContainer p,
.cartCardContainer .cartCardOrderInfo .billingContainer .totalContainer p {
  font-size: 14px;
  line-height: 24px;
  color: #38383a;
}
.cartCardContainer .cartCardOrderInfo .billingContainer .pricesContainer span,
.cartCardContainer .cartCardOrderInfo .billingContainer .totalContainer span {
  font-size: 12px;
  line-height: 16px;
  color: #ffa024;
}
.pricesContainer div {
  margin-bottom: 8px;
}
.cartCardContainer .cartCardOrderInfo .billingContainer .totalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 88px;
  height: 88px;
  background: #f9f9fb;
  border-radius: 500px;
}
.placeOrderBtn {
  margin-bottom: 24px;
  margin-top: 40px;
}
.clearCartBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  /* width: 376px; */
  height: 50px;
  border: 1px solid #f84e4e;
  background-color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #f84e4e;
}

.ordersCardContainer {
  position: absolute;
  top: 160px;
  right: 0;
  left: 0;
  scroll-behavior: smooth;
  overflow: scroll;
  height: 700px;
  padding-bottom: 40px;
  /* left: -100px; */
}
.closeOrdersBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}

.orderAccordionContainer {
  padding: 16px;
  border: 1px dashed #e6e7e9;
  border-radius: 8px;
  margin-bottom: 40px;
}
.orderAccordionHeader p {
  font-size: 14px;
  line-height: 24px;
  color: #565758;
  margin-right: 24px;
}
.orderAccordionHeader .accordionStatus {
  padding: 4px 8px;
  width: 100px;
  height: 24px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
.orderAccordionDetails {
  transition: 0.5s ease-in-out all;
  margin-top: 24px;
  /* border: 1px solid red;
  padding: 20px; */
}

.orderStatusContainer {
  border: 1px dashed #e6e7e9;
  border-radius: 8px;
  padding: 16px 20px;
  background: #f9f9fb;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.orderStatus {
  padding: 8px;
  gap: 8px;
  width: 40px;
  height: 40px;
  border: 0.5px solid #d6d6d8;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orderStatusContainer div {
  border-width: 0.5px;
  border-style: dashed;
  width: 20px;
  margin-top: 25px;
}
.brBottom {
  border-bottom: 0.5px solid #e6e7e9;
  padding-bottom: 8px;
}

.cartFloatingButton {
  padding: 16px;
  position: fixed;
  background: #ffa024;
  border-radius: 8px;
  /* z-index: 10; */
  bottom: 80px;
  left: 20px;
  right: 20px;
  box-shadow: 4px 4px 8px rgba(17, 16, 20, 0.08);
}
.cartFloatingButton .orderCount {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 24px;
  height: 24px;
  background: #ffffff;
  border-radius: 50px;
  margin-right: 16px;
}

.placeOrderContent .activeState {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  width: 50%;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 6px 6px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  color: #ffffff;
}
.placeOrderContent .inactiveState {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  width: 50%;
  height: 40px;
  background: #ebebeb;
  border-radius: 8px;
  color: #696a6b;
}

.placeOrderContent .addressListItem {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e6e7e9;
}

.addressContainerInput {
  padding: 10px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  margin-top: 8px;
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
}

.addressContainerInput p {
  text-align: right;
}
.saveContainer {
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  /* transform: matrix(1, 0, 0, -1, 0, 0); */
  width: 16px;
  height: 16px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeOrderContent {
  margin-top: 15px;
  gap: 16px;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}
.deliveryInfoContainer {
  width: 100%;
  padding: 24px 24px 32px;
  /* width: 848px; */
  background: #ffffff;
  border: 1px solid #e6e7e9;
  box-shadow: 12px 12px 20px rgba(211, 209, 216, 0.08);
  border-radius: 16px;
}
.dashContainer {
  padding: 16px 24px 24px;
  border: 1px dashed #e6e7e9;
  border-radius: 8px;
}
.borderBottom {
  border: 0.5px solid #e6e7e9;
  width: 100%;
  display: flex;
}
.prefContainer {
  flex-direction: column;
  align-items: flex-start;
}
.selectPrefContainer {
  padding: 12px;
  gap: 8px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 6px 6px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectPrefActive {
  background: #fff6eb;
  border: 0.5px solid #ffa024;
  padding: 12px;
  gap: 8px;
  width: 100%;
  box-shadow: 0px 6px 6px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.deliveryMessageContainer {
  width: 100%;
}
.deliveryInfoFlex {
  flex-direction: column;
}

.verifyPageContainer {
  /* margin-top: 100px; */
}

.verifyPaymentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
  background-color: inherit;
}

.addressesContainer {
  padding: 20px 0;
}
.warningModalBtn {
  width: 272px;
}

.cancelButton {
  padding: 12px 16px;
  border: 1px solid #999ca0;
  border-radius: 8px;
  color: #000000;
  background-color: #ffffff !important;
}

.loader-container {
  position: relative;
  width: 200px;
  height: 200px;
}

.image-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 2s infinite linear;
  background: transparent;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.paginationContainer {
  width: 300px;
  margin: 40px auto 20px auto;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  gap: 10px;
}

.paginationButton {
  background-color: rgba(249, 249, 251, 1);
  color: #000;
  height: 25px;
  width: 25px;
  border-radius: 5px;
  font-size: 12px;
}
.activePage {
  background-color: rgba(104, 232, 227, 1);
  color: #fff;
  height: 25px;
  width: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

@media screen and (min-width: 1024px) {
  .onGoBack {
    padding-left: 80px;
    /* margin-top: 100px; */
  }
  #cartHolder {
    display: none;
  }
  .largeImageCardContainer img {
    /* border-radius: 8px; */
    height: 320px;
  }

  .largeImageCardContainer {
    width: 848px;
  }
  .restaurantHomeContainer {
    padding: 40px 80px 0 80px;
    grid-template-columns: 70% 30%;
  }

  .restaurantInfoContainer {
    width: 848px;
  }

  .restaurantInfoContainer .infoTopContainer div:first-child div > p {
    margin-bottom: 0;
  }

  .restaurantInfoContainer .infoBottomContainer > div:nth-child(1) {
    margin-bottom: 0;
  }

  .productsLargeCardContainer {
    width: 848px;
  }
  .productOrderContainer {
    width: 623px;
  }
  .productOrderContainer .productOrderImage img {
    width: 623px;
  }

  .productsLargeCardContainer .productsTabGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .productOrderContainer .productOrderContent {
    padding: 40px;
    border-radius: 0 0 24px 24px;
  }

  #webAddToCart {
    display: block;
  }
  #mobileAddToCart {
    display: none;
  }
  .cartFloatingButton {
    display: none;
  }
  .cartCardContainer {
    width: 416px;
  }

  .ordersCardContainer {
    top: 70px;
    right: 0;
    /* left: 0; */
    left: -250px;
  }

  .placeOrderContent {
    flex-direction: row;
    padding: 0;
    /* justify-content: space-evenly; */
  }
  .deliveryInfoContainer {
    width: 848px;
  }

  .prefContainer {
    flex-direction: row;
    align-items: center;
  }
  .selectPrefContainer {
    width: 50%;
  }
  .selectPrefActive {
    width: 50%;
  }

  .deliveryMessageContainer {
    width: 50%;
  }
  .deliveryInfoFlex {
    flex-direction: row;
    gap: 16px;
  }

  .addressContainerInput {
    padding: 0 10px;
    grid-template-columns: 85% 15%;
    justify-content: space-between;
    height: 48px;
  }
  .verifyPageContainer {
    /* margin-top: 120px; */
  }
}

@media screen and (min-width: 1300px) {
  #cartHolder {
    display: block;
  }
}
