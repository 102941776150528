.landingPageContainer {
  background-color: #fff;
  height: 100vh;
  width: 100vw;
}
.heroSection {
  background-image: url("https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340199654");
  min-height: 880px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 130px 20px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.heroSection header {
  text-align: center;
  margin-bottom: 32px;
}
.heroSection header h2 {
  line-height: 40px;
  position: relative;
  font-size: 36px;
}
.heroSection header p {
  margin-top: 16px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
}
.heroVector {
  position: absolute;
  height: 40px;
  width: 40px;
  top: -10px;
  left: 40px;
}
.heroVector img {
  width: 100%;
  object-fit: contain;
}
.heroAddressForm {
  width: 100%;
  margin-bottom: 22px;
  display: flex;
  gap: 10px;
  /* padding: 0 10px; */
}
.heroFormInputContainer {
  width: 70%;
}
.heroFormButtonContainer {
  width: 30%;
  /* height: 100%; */
}
.heroFormButtonContainer button {
  height: 48px;
}
.heroBtn {
  padding: 14px 16px;
  font-size: 12px;
}
.heroSwiper {
  height: 100px;
  margin: 30px 50px;
}
.heroMockContainer {
  position: absolute;
  bottom: -80px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
}

.secondLayerSection {
  padding: 0;
}

.thirdLayerSection {
  border-radius: 40px;
  background: #fff;
  padding: 64px 20px;
  position: relative;
  top: -20px;
  /* border: 1px solid red; */
  box-shadow: 4px 4px 16px 0px rgba(3, 3, 3, 0.06);
}
.servicesContainer {
  background: #fff;
  /* height: 896px; */
}

.servicesCardContainer {
  border-radius: 16px;
  border: 0.5px solid #f4743b;
  box-shadow: 4px 4px 16px 0px rgba(3, 3, 3, 0.06);
  background-blend-mode: hard-light, normal, normal, normal;
  background-image: url("https://dado-food-store.s3.eu-central-1.amazonaws.com/1710267294432");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.2s;
  color: #fff;
  flex: 0 0 auto;
  width: 300px;
  transition: all 0.1s ease-in-out 0.1s;
}

.servicesGrid::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Webkit browsers */
}

.servicesCardContainer:hover {
  background-image: url("https://dado-food-store.s3.eu-central-1.amazonaws.com/1710267380999");
  border: none;
  color: #000;
  /*  */
}
.servicesCardContainer header {
  padding: 24px;
  text-align: center;
}
.servicesCardContainer header p {
  font-weight: 300;
}
.serviceCardImageContainer {
  border-radius: 16px;
  padding: 24px 24px 0 24px;
  background-color: #fff;
  position: relative;
  max-height: 400px;
}
.serviceCardImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.serviceCardOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(56, 56, 58, 0.4);
  border-radius: 16px;
  display: none;
  transition: all 0.2s ease-in-out 0.3s;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 0 20px;
  z-index: 10;
}

.servicesCardContainer:hover .serviceCardOverlay {
  display: inline-flex;
}
.servicesGrid {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in IE/Edge */
  gap: 40px;
  margin-top: 80px;
}
.maasSection {
  padding: 24px;
  background-image: url("https://dado-food-store.s3.eu-central-1.amazonaws.com/1710284592687");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 24px;
  margin: 80px 0;
  /* height: 1500px; */
}
.maasSection header {
  border-radius: 16px;
  border: 0.2px solid #fef1eb;
  background: rgba(192, 101, 62, 0.06);
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.massHeaderTitle {
  color: #f4743b;
  text-align: center;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
  margin-bottom: 40px !important;
}
.massText {
  color: #fff;
  /* text-align: center; */
  font-family: Amaranth;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 92.857% */
  letter-spacing: 0.28px;
}
.maasGifContainer {
  position: relative;
  margin: 80px 0;
}
.maasGifContainer img {
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.campusSection {
  /* padding: 40px 20px; */
}
.campusContent {
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  width: 100%;
}
.campusImageContainer {
  position: relative;
  height: 489px;
}
.campusImageContainer img {
  width: 100%;
  /* height: 400px; */
  object-fit: cover;
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px rgba(33, 41, 39, 0.1);
}
.campusContentOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}
.campusTab {
  padding: 8px 12px;
  border-radius: 50px;
  background: #fff;
  max-width: 130px;
  text-align: center;
}
.campusContentOverlay p {
  transition: all 0.3s ease-in-out 0.5s;
}
.campusDownloadLinks {
  display: none;
}
.campusContentOverlay:hover .campusDownloadLinks {
  display: inline-flex;
  opacity: 1;
}
.campusPointsGrid {
  border-radius: 16px;
  border: 1px solid #ccdfe1;
  background: #030303;
  box-shadow: 0px 2px 4px 0px rgba(33, 41, 39, 0.1);
  padding: 8px;
  display: grid;
  grid-template-columns: 100%;
  gap: 8px;
}
.campusPointCard {
  border-radius: 8px;
  background: #fff;
  padding: 12px;
  /* width: 100%; */
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.campusPointCardIcon {
  border-radius: 6px;
  background: rgba(68, 149, 255, 0.1);
  padding: 8px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.aboutSection {
  background-image: url("https://dado-food-store.s3.eu-central-1.amazonaws.com/1710267380999");
  /* height: 700px; */
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40px;
  margin: 250px 0 64px 0;
  position: relative;
  padding: 40px 16px;
  /* height: 3100px; */
}
.orderNowSection {
  background-image: url("https://dado-food-store.s3.eu-central-1.amazonaws.com/1710341080639");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px;
}
.aboutVideosContainer {
  height: 350px;
  width: 100%;
  border: 1px solid red;
  position: absolute;
  top: -175px;
  left: 0;
  right: 0;
}

.aboutUsTextContainer {
  margin: 150px 0 50px 0;
  text-align: center;
}

.aboutUsCardContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
}

.aboutUsCard {
  border-radius: 16px;
  border: 1px solid #e6edef;
  background-color: #fff;
  padding: 16px;
}
.aboutUsCard header {
  padding-bottom: 16px;
  border-bottom: 1px solid #e6edef;
  margin-bottom: 24px;
}

.itemIcon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 12px;
}
.aboutUsContactContainer {
  margin: 80px 0;
}
.aboutUsContactContainer header {
  margin-bottom: 40px;
  text-align: center;
}

.item {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 24px;
  border-radius: 16px;
  box-shadow: 4px 4px 8px 0px rgba(17, 16, 20, 0.08);
}

.item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 16px;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.aboutUsContactGrid {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.threeDGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
}
.threeDGrid li {
  position: relative;
}

.threeDGrid img:hover {
  transform: scale(1.04);
  transition: all 0.5s ease-in-out 0.5s;
}

.threeDGrid img {
  width: 100%;
  object-fit: cover;
}

.animatedLogoContainer {
  height: 96px;
  width: 96px;
  border-radius: 50%;
  border: 1px solid rgba(8, 79, 97, 1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: #000;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  /* padding: 10px; */
}
.animatedLogoContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.communitySocialIconContainer {
  border-radius: 100px;
  border: 1px solid #e6fbfa;
  background: #fff;
  backdrop-filter: blur(5px);
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.communityForm {
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: 300px; */
  margin: 0 auto 20px auto;
}

.communityForm input {
  border-radius: 8px 0px 0px 8px;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px #ebebeb;
  border-left: 1px solid #ebebeb;
  background: #fff;
  padding: 12px 0px 12px 12px;
  height: 42px;
  width: 100%;
}

.communityForm button {
  border-radius: 0px 8px 8px 0px;
  background: rgba(3, 174, 167, 1);
  color: #fff;
  padding: 12px 16px;
  font-size: 12px;
}

.aboutUsFooterContainer {
  margin: 80px 0 64px 0;
  text-align: center;
}

.faqSection {
  padding: 40px 20px;
}

.faqAccordion {
  margin-bottom: 24px;
  position: relative;
  border-radius: 16px;
  border: 1px solid #e6e7e9;
  background: #fafafa;
}

.faqAccordionToolTip {
  border-radius: 4.8px;
  box-shadow: 2.4px 2.4px 9.6px 0px rgba(3, 3, 3, 0.06);
  width: 24px;
  height: 24px;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
}
.faqAccordion:hover .faqAccordionToolTip {
  display: inline-flex;
}
.questionToolTip {
  border: 0.3px solid #d6d6d8;
  background: #181819;
  color: #f9f9fb;
}
.answerToolTip {
  border: 0.3px solid #c8e6c9;
  background: #c8e6c9;
  color: #66bb6a;
}

.faqAccordionHeader {
  cursor: pointer;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  /* height: 64px; */
}

.faqAccordionContent {
  overflow: hidden; /* Ensure content doesn't overflow */
  max-height: 100px; /* Set a maximum height for each accordion */
  transition: max-height 0.3s ease-in-out;
  padding: 24px;
  border-radius: 0 0 24px 24px;
  background-color: #f9f9fb;
  color: #101828;
}

.faqAccordion.faqOpen .faqAccordionContent {
  max-height: 300px;
  /*
   Adjust the desired maximum height for the content */
}
.faqGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 64px;
  border-radius: 24px;
  background: #fff;
  padding: 24px;
  background: #181819;
  /* border: 1; */
}

.about-swiper {
  width: 100%;
  position: absolute;
  top: -150px;
  left: 0;
  right: 0;
  /* display: flex; */
  /* align-items: center; */
}
.about-slide-video {
  height: 300px;
}

#video-container-one {
  position: relative;
  right: -30px;
  z-index: 5;
  filter: blur(2px);
}
#video-container-two {
  z-index: 10;
  position: relative;
  left: 0;
  right: 0;
  filter: drop-shadow(4px 4px 16px rgba(3, 3, 3, 0.06));
  transform: scale(1.1);
  width: 800px;
  transform: scaleX(1.2); /* Scale vertically */
  transform-origin: left right; /* Keep the top intact */
  /* transform: skewY(10deg); */
}

#video-container-three {
  position: relative;
  left: -30px;
  z-index: 5;
  filter: blur(2px);
}
.about-slide-video video {
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
  border: 8px solid #fff;
  filter: drop-shadow(4px 4px 16px rgba(3, 3, 3, 0.06));
}

@media screen and (min-width: 768px) {
  .heroSection {
    min-height: 1100px;
    padding: 200px 80px;
  }
  .heroSection header {
    margin-bottom: 30px;
  }
  .heroSection header h2 {
    max-width: 950px;
    margin: 0 auto;
    line-height: 68px;
    font-size: 80px;
    font-weight: 700;
  }
  .heroSection header p {
    line-height: 90px;
  }
  .heroVector {
    height: 108px;
    width: 106px;
    left: -20px;
    top: -40px;
  }
  .heroAddressForm {
    width: 500px;
  }
  .customerLandingPage {
    max-width: 1440px;
    margin: 0 auto;
  }
  .secondLayerSection {
    padding: 0 24px;
  }
  .thirdLayerSection {
    border-radius: 40px;
    top: -30px;
  }
  .heroBtn {
    /* padding:14px; */
    font-size: 16px;
  }
  .heroMockContainer {
    height: 500px;
    bottom: 0;
  }
  .servicesContainer {
    height: auto;
  }

  .servicesCardContainer {
    width: 100%;
    flex: 1;
  }
  .maasSection {
    padding: 80px;
    border-radius: 40px;
    height: auto;
  }
  .maasSection header {
    padding: 64px 0;
    border-radius: 40px;
  }

  .massHeaderTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.32px;
    margin-bottom: 80px !important;
  }

  .massText {
    font-size: 64px;
    line-height: 80px; /* 125% */
    letter-spacing: 0.32px;
  }
  .orderNowSection {
    padding: 40px 100px;
  }
  .campusSection {
    padding: 80px 0 0 0;
  }
  .campusImageContainer {
    height: auto;
  }
  .campusImageContainer img {
    height: 100%;
  }
  .campusContent {
    grid-template-columns: repeat(2, 1fr);
  }
  .campusContentOverlay {
    padding: 40px;
  }

  .aboutSection {
    margin: 360px 0 40px 0;
    padding: 80px;
    /* height: 1300px; */
  }

  .aboutUsTextContainer {
    max-width: 1000px;
    margin: 150px auto 100px auto;
    /* text-align: start; */
  }

  .aboutUsCardContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  .aboutUsContactContainer header {
    margin-bottom: 80px;
  }

  .item {
    width: 480px;
    height: 312px;
  }

  .aboutUsContactGrid {
    flex-wrap: nowrap;
    /* gap: 24px; */
  }

  .animatedLogoContainer {
    height: 184px;
    width: 184px;
    /* padding: 20px; */
  }

  .about-swiper {
    height: 480px;
    width: 480px;
    left: 35%;
    top: -250px;
  }

  .communitySectionContainer {
    padding: 40px 80px;
  }

  .faqSection {
    padding: 0 80px 40px 80px;
  }
  .faqGrid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    padding: 40px;
  }
}

@media only screen and (min-width: 1800px) {
  .heroSection,
  .thirdLayerSection,
  .aboutSection,
  .orderNowSection,
  .faqSection {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
}
