.flexRow {
  display: flex;
  flex-direction: row;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexWrap {
  flex-wrap: wrap;
}
.noWrap {
  flex-wrap: nowrap;
}
.justifyBetween {
  justify-content: space-between;
}

.justifyCenter {
  justify-content: center;
}
.justifySelfEnd {
  justify-self: flex-end;
}
.justifySelfCenter {
  justify-self: center;
  /* border: 1px solid red; */
}

.alignCenter {
  align-items: center;
}

.pb8 {
  padding-bottom: 8px;
}
.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pt25 {
  padding-top: 25px;
}

.pt30 {
  padding-top: 30px;
}

.pt35 {
  padding-top: 35px;
}
.pt38 {
  padding-top: 38px;
}

.pt40 {
  padding-top: 40px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}
.pb16 {
  padding-bottom: 16px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb25 {
  padding-bottom: 25px;
}

.pb24 {
  padding-bottom: 24px;
}
.pb28 {
  padding-bottom: 28px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb35 {
  padding-bottom: 35px;
}

.pb40 {
  padding-bottom: 40px;
}
.pb48 {
  padding-bottom: 48px;
}
.pb58 {
  padding-bottom: 58px;
}
.pl10 {
  padding-left: 10px;
}

.pl15 {
  padding-left: 15px;
}

.pl20 {
  padding-left: 20px;
}

.pl25 {
  padding-left: 25px;
}

.pl30 {
  padding-left: 30px;
}

.pl35 {
  padding-left: 35px;
}

.pl40 {
  padding-left: 40px;
}

.pr10 {
  padding-right: 10px;
}

.pr15 {
  padding-right: 15px;
}

.pr20 {
  padding-right: 20px;
}

.pr25 {
  padding-right: 25px;
}

.pr30 {
  padding-right: 30px;
}

.pr35 {
  padding-right: 35px;
}

.pr40 {
  padding-right: 40px;
}

.pr50 {
  padding-right: 50px;
}

.pr60 {
  padding-right: 60px;
}

.pr70 {
  padding-right: 70px;
}

.pl50 {
  padding-left: 50px;
}

.pl60 {
  padding-left: 60px;
}

.pl70 {
  padding-left: 70px;
}

.mt10 {
  margin-top: 10px;
}

.mt38 {
  margin-top: 38px;
}
.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}

.ml10 {
  margin-left: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 20px;
}

.mb20 {
  margin-bottom: 30px;
}

.mb5 {
  margin-bottom: 5;
}

.mt5 {
  margin-bottom: 5;
}

.mr5 {
  margin-bottom: 5;
}

.ml5 {
  margin-bottom: 5;
}
.ml18 {
  margin-left: 18px;
}

.cPointer {
  cursor: pointer;
}

.hAuto {
  height: auto;
}

.h100 {
  height: 100%;
}

.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}

.content-container {
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .content-container {
    width: 1440px;
    max-width: 1440px;
  }
} 
