.riderAgentNavContainer {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
}
.profileImageContainer {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  border: 1px solid rgba(4, 217, 209, 1);
  /* justify-self: center; */
  margin: 0 0 20px 0;
  background-color: #000;
}
.profileImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 100px !important;
}

.riderAgentNavContainer .logoContainer img {
  width: 48px;
  height: 48px;
}

.navListContainer {
  background: rgba(3, 32, 39, 1);
  border-radius: 100px;
  padding: 5px;
}

.navListContainer ul {
  display: flex;
  gap: 16px;
}

.navLink {
  padding: 8px;
  gap: 0;
  border-radius: 100px;
  border: 1px solid transparent;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s;
}

.navLinkInactive {
  background-color: #fff;
  color: rgba(86, 87, 88, 1) !important;
}

.navLinkActive {
  background-color: rgba(4, 217, 209, 1);
  color: #fff;
  border-color: rgba(4, 217, 209, 1);
  opacity: 1;
}

.notificationBar {
  display: none;
}

.menuBar {
  display: block;
}

.riderAgentContainer {
  padding: 20px;
  height: 100vh;
}

.riderAgentContainer header {
  text-align: center;
  padding: 0 0 20px 0;
}

.riderAgentLoginContainer {
  width: 100%;
  margin-top: 60px;
}

.ordersTableContainer {
  background-color: #fff;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 24px;
  padding: 18px 10px 10px 10px;
  position: relative;
  /* margin-top: 40px; */
}

.ordersTableNav {
  font-family: "Amaranth", sans-serif;
  color: rgba(56, 56, 58, 1);
}

.orderTableNavIconContainer {
  border: 1px solid rgba(24, 24, 25, 1);
  border-radius: 100px;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ordersTableContentContainer {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 24px;
  width: 100%;
  margin-top: 30px;
  border-collapse: collapse;
  overflow-x: auto; /* Allow horizontal scrolling */
}

.ordersTableContentHeader {
  background-color: rgba(249, 249, 251, 1);
  border-bottom: 1px solid rgba(237, 242, 247, 1);
  border-radius: 24px 24px 0 0 !important;
}

.ordersTableContentHeader th {
  padding: 10px;
  text-align: left;
  color: rgba(145, 146, 148, 1);
  font-family: "Amaranth", sans-serif;
  font-weight: 700;
  font-size: 12px;
}

.ordersTableContentContainer td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.ordersTableContentContainer th,
.ordersTableContentContainer td {
  text-align: center; /* Center align text */
  width: auto; /* Ensure proper width handling */
  padding: 16px 0;
}

.ordersTableContentContainer tbody {
  color: rgba(56, 56, 58, 1);
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  display: block;
  max-height: 355px; /* Adjust based on available height */
  overflow-y: auto; /* Allow vertical scrolling */
}

.ordersTableContentContainer thead,
.ordersTableContentContainer tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.ordersTableTabContainer {
  /* transform: translateX(-50%); */
  width: 280px;
  height: 42px;
  background-color: rgba(3, 32, 39, 1);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.ordersTableTabContainer button {
  border: none;
  height: 100%;
  background-color: inherit;
  flex: 1;
  color: rgba(249, 249, 251, 1);
  font-family: "Amaranth", sans-serif;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  border-radius: 40px;
  outline: none;
}

.ordersTableTabContainer .active {
  background-color: rgba(4, 217, 209, 1);
  border-radius: 40px;
  padding: 8px;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease;
}

.activeOrdersContainer {
  isolation: isolate;
  background: inherit;
  height: auto;
  margin-top: 40px;
}
.activeOrdersContainer .activeOrdersHeader {
  padding: 0 0 16px 0;
  background-color: inherit;
}

.activeOrdersCard {
  padding: 16px 16px 24px;
  background: #f9f9fb;
  border: 1px solid #66bb6a;
  border-radius: 16px;
  margin-bottom: 16px;
}
.activeOrdersCard .activeOrdersCardHeader {
  border-bottom: 0.5px solid #d6d6d8;
  padding-bottom: 8px;
  align-items: flex-start;
}
.activeOrdersCard .activeOrdersCardFooter {
  border-top: 0.5px solid #d6d6d8;
  padding-top: 8px;
  margin-top: 16px;
}
.activeOrdersCard .userOrderAvatar {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 1px solid #66bb6a;
  margin-right: 8px;
}
.activeOrderCardStatusBox {
  padding: 2px 8px;
  background: #e1e8ff;
  border-radius: 8px;
  font-size: 10px;
  color: #4c6fff;
  text-transform: uppercase;
}
.activeOrderView {
  padding: 4px 8px;
  border: 1px solid rgba(4, 217, 209, 1);
  border-radius: 8px;
  color: rgba(4, 217, 209, 1);
}

.walletHistoryContainer {
  margin-top: 40px;
}
.walletHistoryContainer h3 {
  font-size: 20px;
}

.cardWithHeaderContainer {
  padding: 16px 22px;
  background: #ffffff;
  border: 1px dashed #ffffff;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 8px;
  margin: 16px 0;
}
.cardWithHeaderContainer .cardWithHeaderHeader {
  border-bottom: 0.5px solid #e6e7e9;
  padding-bottom: 16px;
  margin-bottom: 24px;
}

/* Modal background */
.riderAgentModal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  /* justify-content: flex-end; */
  align-items: flex-end !important;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.riderAgentModal.open {
  opacity: 1;
  visibility: visible;
  align-items: center; /* Center the modal when open */
}

/* Modal content */
.riderAgentModalContent {
  background: white;
  width: 100%;
  /* max-width: 100%; */
  padding: 40px 20px;
  border-radius: 16px 16px 0 0;
  transform: translateY(100%); /* Start from the bottom */
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}

.riderAgentModal.open .riderAgentModalContent {
  transform: translateY(0); /* Move to the center */
  opacity: 1;
}

.walletListItem {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.riderAgentResponsiveContainer {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .riderAgentNavContainer {
    padding: 20px 80px;
  }

  .notificationBar {
    display: block;
  }

  .menuBar {
    display: none;
  }

  .riderAgentLoginContainer {
    width: 600px;
    margin: 60px auto 0 auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 40px;
    border-radius: 16px;
  }

  .riderAgentModal {
    align-items: center !important;
  }
  .riderAgentModalContent {
    max-width: 400px;
    transform: translateY(0);
    opacity: 1;
    border-radius: 16px;
    margin: auto;
  }
  .riderAgentResponsiveContainer {
    max-width: 500px;
    margin: auto;
  }
}
