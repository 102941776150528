.vendorContainer {
  background-image: url("https://dado-food-store.s3.eu-central-1.amazonaws.com/1710266482044");
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  /* padding: 20px; */
}

.vendorHeroSection {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 20px;
}
.vendorHeroSection header {
  position: relative;
}

.vendorHeroSection header h2 {
  color: #032027;
  text-align: center;
  font-family: Amaranth;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 111.111% */
  /* width: 100%; */
}
.vendorHeroSection header p {
  color: #052f3a;
  text-align: center;

  /* Body/Medium/Light */
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: 24px; /* 150% */
  letter-spacing: 0.08px;
}

.vendorHeroIcon {
  position: absolute;
  right: 35px;
  top: 45px;
  width: 33px;
  height: 38px;
}
.vendorVideoSection {
  position: relative;
  height: 1000px;
  width: 100%;
}
.vendorVideoStyle {
  border-radius: 40px;
  height: 100%;
  object-fit: cover;
}
.vendorVideoOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 40px;
  padding: 40px 0;
}
.vendorVideoOverlay .vendorFlow {
  width: 100%;
}

.vendorVideoCardsContainer {
  border-radius: 16px;
  background: rgba(2, 87, 84, 0.2);
  box-shadow: 0px 2px 4px 0px rgba(33, 41, 39, 0.1);
  padding: 24px;
  margin: 40px 10px 0 10px;
}
.vendorVideoCardsContainer header {
  padding-bottom: 20px;
}
.vendorVideoCardsContainer header h4 {
  color: #f4743b;
  text-align: center;
  font-family: Amaranth;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;
}
.vendorVideoCardsContainer header p {
  color: #e6edef;
  text-align: center;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;
}

.vendorVideoCardsContainerGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.vendorSecondSection {
  border-radius: 40px;
  box-shadow: 4px 4px 16px 0px rgba(3, 3, 3, 0.06);
  display: flex;
  padding: 68px 12px 16px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 80px; */
  background: #011014;
  position: relative;
  top: -40px;
  /* width: 0%; */
  margin: 0 10px;
}
.vendorSecondSection header {
  color: rgba(230, 237, 239, 1);
  margin-bottom: 80px;
}
.vendorSecondSection header h4 {
  color: #f4743b;

  /* Body/Small/Semibold */
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;
}
.vendorSecondSection header h3 {
  color: #f9f9fb;
  font-family: Amaranth;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}
.vendorSecondSection header p {
  color: #e6edef;

  /* Body/Small/Light */
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.07px;
}

.vendorHeroGifContainer {
  height: 80px;
  width: 80px;
  border-radius: 100px;
  background: #fff;
  padding: 10px;
  position: absolute;
  top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vendorHeroGifContainer span {
  border-radius: 150px;
  border: 0.714px solid #f9f9fb;
  background: #04d9d1;
  display: flex;
  width: 57.143px;
  height: 57.143px;
  padding: 7px;
  justify-content: center;
  align-items: center;
}
.vendorHeroGifContainer img {
  border-radius: 100px;
}
.vendorSecondSectionContent {
  background-image: url("https://dado-food-store.s3.eu-central-1.amazonaws.com/1710267380999");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 24px;
  width: 100%;
}
.vendorSecondSectionContent .riderFlow {
  width: 100%;
}
.vendorSecondSectionContentFooter {
  border-radius: 16px;
  border: 0.2px solid #fef1eb;
  background-image: url("https://dado-food-store.s3.eu-central-1.amazonaws.com/1710340943862");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* opacity: 0.5; */
  margin: 0 8px 20px 8px;
  padding: 40px 10px;
}
.vendorSecondSectionContentFooter header h4 {
  color: #025754;
  text-align: center;
  font-family: Amaranth;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  letter-spacing: 0.1px;
}
.vendorSecondSectionContentFooter header p {
  color: #052f3a;
  text-align: center;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px; /* 133.333% */
  letter-spacing: 0.12px;
}
.vendorText {
  color: rgba(230, 251, 250, 1);
  text-align: center;
  font-family: Amaranth;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 128.571% */
  letter-spacing: 0.28px;
}
.vendorYellowBgText {
  color: #032027;
  text-align: center;
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.12px;
  padding: 10px;
}
.vendorAboutSection {
  margin-top: -150px;
}

@media screen and (min-width: 768px) {
  .vendorHeroSection {
    gap: 64px;
  }
  .vendorHeroIcon {
    position: absolute;
    right: -85px;
    top: 0;
    width: 105px;
    height: 123px;
  }

  .vendorHeroSection header h2 {
    color: #032027;
    text-align: center;

    /* Header/H2 */
    font-family: Amaranth;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 96px; /* 120% */
    letter-spacing: 0.8px;
  }
  .vendorHeroSection header p {
    color: #063f4e;
    text-align: center;

    /* Body/Big/Light */
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px; /* 140% */
    letter-spacing: 0.1px;
  }
  .vendorVideSection {
    position: relative;
    /* height: 1300px; */
  }
  .vendorVideoStyle {
    border-radius: 40px;
    height: 1100px;
    width: 100%;
  }
  .vendorVideoOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 40px;
  }
  .vendorVideoOverlay .vendorFlow {
    width: 100%;
  }

  .vendorVideoCardsContainer {
    margin: 160px 66px 0 66px;
  }
  .vendorVideoCardsContainer header h4 {
    font-family: Rubik;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.08px;
  }
  .vendorVideoCardsContainer header p {
    font-size: 20px;
    line-height: 28px; /* 140% */
    letter-spacing: 0.1px;
  }
  .vendorVideoCardsContainerGrid {
    flex-wrap: nowrap;
  }
  .vendorSecondSection {
    top: 50px;
    padding: 48px 40px;
    border-radius: 80px;
  }

  .vendorsSecondSection header {
    padding-right: 80px;
  }
  .vendorSecondSection header h4 {
    color: #f4743b;

    /* Body/Medium/Semibold */
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.08px;
  }
  .vendorSecondSection header h3 {
    color: #e6edef;

    font-family: Amaranth;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
  }
  .vendorSecondSection header p {
    color: #e6edef;

    /* Body/Big/Light */
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px; /* 140% */
    letter-spacing: 0.1px;
  }
  .vendorSecondSectionContent {
    border-radius: 40px;
  }
  .vendorSecondSectionContentFooter {
    border-radius: 40px;
    margin: 0 20px 32px 20px;
    padding: 80px 160px 120px 160px;
  }
  .vendorSecondSectionContentFooter header h4 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px; /* 120% */
    letter-spacing: 0.1px;
  }
  .vendorSecondSectionContentFooter header p {
    font-size: 24px;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.12px;
  }
  .vendorText {
    font-size: 64px;
    line-height: 80px; /* 125% */
    letter-spacing: 0.32px;
  }
  .vendorYellowBgText {
    font-size: 20px;
    line-height: 28px; /* 133.333% */
    letter-spacing: 0.12px;
  }
}
