.signUpCardsSection {
  display: grid;
  grid-template-columns: repeat(fill, minmax(350px, 1fr));
  gap: 22px;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 124px 20px 200px 20px;
}
.signUpCardContainer {
  background: #fafafa;
  border: 0.5px solid #e6e7e9;
  box-shadow: -40px 60px 40px rgba(1, 1, 1, 0.04);
  border-radius: 16px;
}
.signUpCardImage {
  background: rgba(1, 1, 1, 0.2);
  border-radius: 16px 16px 0 0;
  max-height: 330px;
  height: 330px;
}
.signUpCardContainer img {
  width: 100%;
  height: 100%;
}

.signUpCardContent {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  text-align: center;
  padding: 40px 20px;
}

.signUpCardContent h2 {
  color: #181819;
}
.signUpCardContent p {
  padding: 16px 0 40px 0;
  color: #181819;
}
.signUpCardBtn {
  padding: 0 40px;
}

/* vendor Section  */
.vendorsHeroSection {
  background-image: url("https://dado-food-store.s3.eu-central-1.amazonaws.com/1684751998590");
  height: 580px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 20px;
}

.vendorsHeroContent h1 {
  font-weight: 800;
  font-size: 38px;
  line-height: 48px;
  color: #ffffff;
  margin-bottom: 24px;
}
.vendorsHeroContent p {
  font-size: 20px;
  line-height: 30px;
  color: #e6e7e9;
  margin-bottom: 40px;
}

/* why Section  */
.whySection {
  padding: 80px 20px;
}

.whySection h2 {
  font-weight: 800;
  font-size: 38px;
  line-height: 56px;
  margin-bottom: 24px;
}
.whySection p {
  font-size: 20px;
  line-height: 30px;
}

/* how Section  */

.howSection {
  padding: 40px 20px;
  background: #f9f9fb;
}
.howSection h2 {
  font-weight: 800;
  font-size: 38px;
  line-height: 56px;
}
.howSectionContent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 48px;
}

.howListContainer {
  display: grid;
  grid-template-columns: 10% 80%;
  gap: 24px;
  margin-bottom: 64px;
}
.howListContainer div:first-child {
  width: 40px;
  height: 40px;
  background: #ffa024;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 28px;
}
.howListContainer h5 {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 16px;
}
.howListContainer p {
  font-size: 16px;
  line-height: 24px;
}

.webVendorMockUp {
  display: none;
  /* order: 2; */
}
.mobileVendorMockUp {
  order: 1;
  /* display: block; */
}
.mobileCenter {
  justify-content: center;
}
.mobileCenter img {
  height: 48px;
}
.keepTrackSection {
  padding: 80px 20px;
  background: #f9f9fb;
  display: flex;
  flex-direction: column;
}
.keepTrackContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  order: 2;
}
.keepTrackContent h2 {
  font-weight: 800;
  font-size: 38px;
  line-height: 52px;
  letter-spacing: -0.02em;
  color: #101828;
  margin-bottom: 24px;
  text-align: center;
}
.keepTrackContent p {
  font-size: 20px;
  line-height: 30px;
  color: #667085;
  margin-bottom: 48px;
  text-align: center;
}

.keepTrackSection picture {
  justify-content: center !important;
  /* border: 1px solid red; */
  display: flex;
}

.vendorWhySection {
  padding: 80px 20px;
}
.vendorWhySection h2 {
  font-weight: 800;
  font-size: 38px;
  line-height: 52px;
  color: #181819;
  margin-bottom: 80px;
}

.vendorWhySectionGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px 16px;
}

.customerLandingPage {
  /* margin-top: 200px; */
}
.homeHeroSection {
  display: none;
  background-image: url("../images/homeHero.png");
  height: 352px;
  width: 100%;
}
.homeHeroSection h1 {
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  color: #f9f9fb;
  margin-top: 30px;
}
.homeHeroSection p {
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #e6e7e9;
}

.miniFoodCardContainer {
  padding: 8px 8px 12px 8px;
  width: 96px;
  height: auto;
  background: #ffffff;
  border: 1px solid #f0f1f2;
  /* box-shadow: 11.0626px 11.0626px 22.1253px rgba(211, 209, 216, 0.25); */
  border-radius: 12px;
}
.miniFoodCardContainer img {
  height: 80px;
}

.miniFoodCardContainer p:nth-child(1) {
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #38383a;
  margin-top: 8px;
  /* margin-bottom: 5px; */
}
.miniFoodCardContainer p:nth-child(2) {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #919294;
}

.categoriesSection {
  padding: 20px;
}
.categoriesSection h3 {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #181819;
  margin-bottom: 24px;
}
.categoriesGrid {
  display: flex;
  align-items: center;
  gap: 0 16px;
  /* justify-content: space-between; */
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  /* scrollbar-width: none; */
}
.categoriesGrid::-webkit-scrollbar {
  display: none;
}

.categoriesCarousel {
  padding: 0 20px;
}
.categoriesCarousel h4 {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #38383a;
  margin-bottom: 16px;
}
.carouselFlex {
  margin-bottom: 30px;
}

.carouselFlex::-webkit-scrollbar {
  display: none;
}
#leftAlign {
  /* justify-content: center; */
}

.foodCardContainer {
  padding: 8px 8px 0 8px;
  background: #ffffff;
  border: 1px solid #f0f1f2;
  box-shadow: 18.6269px 18.6269px 37.2537px rgba(211, 209, 216, 0.25);
  border-radius: 16px;
  width: auto;

  /* position: relative; */
}

.foodCardContent {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 16px 16px 12px 16px;
  justify-content: space-between;
  /* align-items: ce; */
}
.foodCardContent p {
  font-size: 12px;
  line-height: 16px;
  color: #38383a;
}

.foodCardContent p:last-child {
  color: #919294;
}
.foodCardContent li {
  font-size: 14px;
  line-height: 24px;
  margin-right: 10px;
  text-transform: capitalize;
  /* margin-bottom: 5px; */
}
.foodCardTags {
  display: flex;
}

.foodCardImageContainer {
  position: relative;
  border-radius: 16px;
}

.foodCardImageOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, rgba(1, 1, 1, 0.8), rgba(1, 1, 1, 0.8));
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.foodCardImageContainer .prepTime {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 4px;
  background: #f9f5ff;
  border-radius: 20px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.foodCardContent span {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  line-height: 16px;
  color: #38383a;
  /* align-items: center; */
}
.foodCardContent span img {
  width: 14px;
  height: 13px;
  margin-right: 5px;
  /* margin-top: 5px; */
}
.categoriesFoodCard {
  width: 247px;
  height: auto;
}
.categoriesFoodCard img {
  width: 100%;
  height: 120px;
}

.allRestaurantsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 40px 16px;
}

.privacyHero {
  background-image: url("../images/privacyHero.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  z-index: 4;
  padding-top: 40px;
  margin-bottom: 80px;
}

.privacyHeader {
  padding-left: 20px;
  font-size: 24px;
  margin-bottom: 24px;
}

.privacyHero h2 {
  font-size: 32px;
}
.privacyHero p {
  font-size: 16px;
}

.privacyArticle {
  padding: 0 20px;
  margin-bottom: 24px;
  color: #ffffff;
}
.privacyTerms {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  background-color: #38383a;
}

/* tablets */
@media screen and (min-width: 800px) and (max-width: 1023px) {
  .heroSection {
    height: 500px;
  }

  .heroDownloadLinks {
    width: 200px;
  }

  .heroContent h1 {
    font-size: 48px;
  }
  .heroVector {
    left: -20px;
  }

  .heroOverlayContent {
    width: 100%;
  }
  .heroLeftImageContainer {
    top: 70px;
  }
  .heroLeftImageContainer img {
    width: 80%;
  }
  .heroRightImageContainer {
    right: 0px;
    top: 100px;
  }
  .signUpCardsSection {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    padding: 124px 20px 200px 20px;
  }
  .signUpCardContainer {
    max-height: 650px;
    height: 598px;
  }
  .signUpCardContent {
    padding: 20px;
  }

  .signUpCardContent p {
    padding: 16px 0 20px 0;
  }
  .satisfactionContainer {
    flex-direction: row;
  }
  .satisfactionContainerLeft {
    padding: 0 0 0 20px;
  }
  .satisfactionHeader {
    margin-bottom: 0;
  }
  .downloadLinksContainer {
    justify-content: flex-start;
  }
  .satisfactionImageContainer {
    width: 100%;
    margin-top: 0;
  }

  #webMockUp {
    display: block;
  }
  #mobileMockUp {
    display: none;
  }
  .signUpCardImage {
    /* height: 330px; */
    /* max-height: 330px; */
  }
}

/* wide screens */
@media screen and (min-width: 1024px) {
  .heroSection {
    height: 700px;
    /* border-radius: 0 0 50px 30%; */
  }
  .heroContent {
    padding-top: 100px;
  }
  .heroContent h1 {
    font-size: 4rem;
  }
  .heroContent p {
    font-size: 32px;
  }
  .heroVector {
    left: -20px;
  }

  .signUpCardsSection {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    padding: 192px 81px 200px 81px;
  }
  .signUpCardContainer {
    max-height: 650px;
    height: 598px;
  }

  #webMockUp {
    display: block;
  }
  #mobileMockUp {
    display: none;
  }
  .customerLandingPage {
    /* margin-top: 50px; */
  }

  .homeHeroSection {
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
  }
  .categoriesSection {
    padding: 80px 242px;
  }
  .categoriesSection h3 {
    font-size: 24px;
    line-height: 36px;
  }
  .categoriesGrid {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: auto;
    justify-content: center;
  }
  #leftAlign {
    justify-content: flex-start;
  }
  .miniFoodCardContainer {
    box-shadow: 11.0626px 11.0626px 22.1253px rgba(211, 209, 216, 0.25);
  }

  .categoriesCarousel {
    padding-left: 80px;
    /* padding-right: 80px; */
    padding-bottom: 80px;
    z-index: 2;
  }
  .allRestaurantsContainer {
    padding: 0 80px 80px 80px;
  }
  .categoriesCarousel h4 {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 40px;
  }
  .carouselFlex {
    /* width: 100vw; */
    z-index: 1;
    padding-right: 80px;
    margin-bottom: 80px;
  }
  .foodCardContainer {
    width: 416px !important;
    /* height: 288px !important; */
  }

  .foodCardContent p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
  }

  .foodCardContent span {
    font-size: 16px;
    line-height: 24px;
  }

  .categoriesFoodCard img {
    width: 100%;
    height: 200px;
  }
  .allRestaurantsGrid {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 40px 16px;
  }
  .foodCardContent span img {
    margin-top: 5px;
  }

  .privacyHero {
    height: 352px;
    padding-top: 80px;
    margin-bottom: 120px;
  }

  .privacyHeader {
    padding-left: 84px;
    font-size: 48px;
  }
  .privacyHero h2 {
    font-size: 48px;
  }
  .privacyHero p {
    font-size: 24px;
  }
  .privacyArticle {
    padding: 0 86px;
  }
}
