.avatarMainContainer {
  position: relative;
}
.avatarContainer {
  border-radius: 99px;
  height: 48px;
  width: 48px;
  background: #ff9000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: none;
}
.avatarMainContainer .logoutDrop {
  position: absolute;
  left: -40px;
  /* top: 10px; */
  background: #fff;
  padding: 10px;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  cursor: pointer;
}
/* input  */

.inputContainer {
  margin-bottom: 16px;
  height: auto;
}
.input {
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  margin-top: 10px;
  outline: none;
}

/* button  */
.button {
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  color: #ffffff;
}

.multiButton {
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Loader  */

.loader {
  height: 30px;
  width: 30px;
  border: 1px solid rgba(3, 174, 167, 1);
  border-radius: 50%;
  border-top: rgba(3, 174, 167, 1);
  animation: circular-loader 2s linear infinite;
}

@keyframes circular-loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* backComponent  */

.backComponentContainer {
  border-radius: 50%;
  border: 1px solid #000;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Accordion  */

.accordionContainer {
  margin-bottom: 32px;
}
.accordionContainer h5 {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
  width: 680px;

  /* Gray/900 */

  color: #101828;
}
.accordionContent {
  transition: all 0.3s ease-in-out 0s;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Gray/500 */

  color: #667085;
  padding-right: 30px;
}
.accordionStatus {
}

.searchBarContainer {
  width: 100%;
  height: 38px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 16px;
  margin-right: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
}

.locationInputContainer {
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: 100%;
}
.locationInputContainer label {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 8px;
}
.locationInputContainer input {
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 12px;
}
.locationInputContainer input::placeholder {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #999ca0;
}

.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 16px;
  margin: auto;
}

.otp-input {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  height: 48px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  font-family: "Nunito";
}

.counterContainer {
  padding: 8px 12px;
  gap: 16px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #b1b2b4;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.customSelectContainer {
  padding: 0 16px;
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.customSelectListContainer {
  position: absolute;
  top: 49px;
  background-color: #ffffff;
  left: 0;
  right: 0;
  border-radius: 8px;
  padding: 20px 10px;
}
.customSelectListItem {
  margin-bottom: 16px;
}
.customSelectListItem:hover {
  background-color: #999ca0;
}
.notFoundContainer {
  height: 100vh;
}

.notFoundImage {
  width: 400px;
  height: 400px;
  margin: auto auto 40px auto;
}

.autoCompletedContainer {
  padding: 20px;
  border-radius: 0 0 12px 12px;
  background-color: #ffffff;
  overflow: scroll;
  max-height: 200px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.autoCompletedContainer li {
  margin-bottom: 8px;
  color: #667085;
  border-bottom: 0.5px solid #e6e7e9;
  padding-bottom: 8px;
}

@keyframes ldio-68bts848xrf {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-68bts848xrf div {
  position: absolute;
  width: 38px;
  height: 38px;
  border: 10px solid #ffa024;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-68bts848xrf div {
  animation: ldio-68bts848xrf 1.1363636363636365s linear infinite;
  top: 50px;
  left: 50px;
}
.loadingio-spinner-rolling-qknyt5f6jt {
  width: 18px;
  height: 18px;
  display: inline-block;
  overflow: hidden;
  background: #f1f2f3;
}
.ldio-68bts848xrf {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.18);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-68bts848xrf div {
  box-sizing: content-box;
}

.fullScreenLoader {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* SmartBanner Base Styles */
.smart-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #f8f9fa;
  color: #000;
  z-index: 9999;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  padding: 10px 20px;
  align-items: center;
  transform: translateY(-100%);
  transition: transform 0.4s ease-in-out;
}

/* Adjust navbars when smart banner is visible */
.homeNavContainer.with-smart-banner {
  top: 50px; /* Adjust by the banner's height */
  transition: transform 0.4s ease-in-out;
}

.customerLandingPage.with-smart-banner {
  margin-top: 150px; /* Adjust by the banner's height plus any extra padding */
  transition: transform 0.4s ease-in-out;
}
/* Slide animations */
.slide-down {
  transform: translateY(0);
}

.slide-up {
  transform: translateY(-100%);
}

/* SmartBanner Content */
.smart-banner-content {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;
}

.smart-banner-text h3 {
  margin: 0;
  font-size: 12px;
}

.smart-banner-text p {
  margin: 0;
  font-size: 10px;
}

.smart-banner-buttons {
  display: flex;
  gap: 10px;
}

.smart-banner-button {
  text-decoration: none;
  color: #fff;
  padding: 8px;
  border-radius: 16px;
  width: 50px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Amaranth", sans-serif;
  font-weight: 600;
  font-size: 10px;
}

/* 4ebb08ec475d403694a89f554dfba18a */
.smart-banner-button {
  background-color: #007aff;
}

.smart-banner-close {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .avatarContainer {
    display: flex;
  }
  .searchBarContainer {
    height: auto;
    border-radius: 20px;
    padding: 10px 16px;
  }
  .homeNavContainer.with-smart-banner {
    top: 0; /* Adjust by the banner's height */
  }

  .customerLandingPage.with-smart-banner {
    margin-top: 0; /* Adjust by the banner's height plus any extra padding */
  }
}
