.contentContainer {
  padding: 25px 20px 0 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.authCardSection {
  border: 1px solid #ffdfb6;
  border-radius: 24px 24px 0 0;
  padding: 80px 20px 40px 20px;
  background-color: #ffffff;
  position: relative;
  width: 100%;
  margin: 0;
  overflow: scroll;
}

.vendorAuthModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.vendorCloseBtn {
  padding: 8px;
  position: absolute;
  width: 40px;
  height: 40px;
  background: #f0f1f2;
  border: 1px solid #f9f9fb;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 24px;
  right: 24px;
}
.modalBackButton {
  border: 1px solid #000000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 24px;
  left: 24px;
}

.vendorAuthContainer {
  z-index: 5;
  scroll-behavior: smooth;
  overflow: scroll;
}
.vendorAuthContainer h2 {
  font-size: 18px;
  line-height: 28px;
  color: #181819;
}
.vendorAuthContainer p {
  font-size: 16px;
  line-height: 24px;
  color: #565758;
  margin-bottom: 40px;
}
.vendorFormContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px 0;
}
#height {
  overflow-y: scroll;
  /* overflow: hidden; */
  height: 400px !important;
  scroll-behavior: smooth;
}
.vendorBtnContainer {
  width: 100%;
  margin-top: 40px;
}
.vendorAuthCompletedImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0 37px 0;
}

.progressBar {
  display: flex;
  /* justify-content: space-between;ss */
  align-items: center !important;
  width: 200px;
  margin: 24px auto 0 auto;
}
.progressBar p:nth-child(1) {
  width: 40px;
  height: 40px;
  background: #ffa024;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progressBar span {
  background-color: #e6e7e9;
  width: 120px;
  height: 7px;
  position: relative;
  top: -20px;
}
.progressBar span .progressLength {
  position: absolute;
  top: 0;
  bottom: 0;
  /* width: 60px; */
  background-color: #ffa024;
}
.progressBar p:nth-child(3) {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e6e7e9;
}

@media screen and (min-width: 1024px) {
  .contentContainer {
    padding: 25px 20px 0 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .authFormContainer {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
  }
  .authContainer {
    height: 1400px;
    /* width: ; */
    /* border: 1px solid red; */
    background-color: #ffffff;
    position: relative;
  }
  .authCardSection {
    border: 1px solid #ffdfb6;
    border-radius: 24px;
    padding: 40px;
    background-color: #ffffff;
    position: relative;
    width: 1064px;
    margin: auto;
  }
  .authCardImage {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    bottom: 0;
    left: 0;
    right: 0;
    top: 90%;
    /* background-color: rgba(0, 0, 0, 0.5); */
  }
  .authCardImage img {
    width: 100%;
  }
  .authSectionOverlay {
    position: absolute;
    top: 150px;
    right: 0;
    left: 0;
    bottom: 0;
    /* background-color: rgba(0, 0, 0, 0.5); */
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-bottom-left-radius: 24px; */
    /* border-bottom-right-radius: 24px; */
  }
  .authSectionOverlay img {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }
  .vendorAuthModal {
    align-items: center;
  }
  .vendorAuthContainer {
    padding: 24px 120px;
    z-index: 5;
    /* border: 1px solid red; */
  }
  .vendorAuthContainer h2 {
    font-size: 38px;
    line-height: 56px;
    color: #181819;
  }
  .vendorAuthContainer p {
    font-size: 20px;
    line-height: 30px;
    color: #565758;
    margin-bottom: 0px;
  }
  .vendorFormContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 44px 16px;
    padding: 50px 0;
  }
  .vendorBtnContainer {
    width: 50%;
    margin: auto auto 40px auto;
  }
  .vendorAuthCompletedImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0 37px 0;
  }
  .progressBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    margin: 24px auto 24px auto;
  }
  .progressBar p:nth-child(1) {
    width: 40px;
    height: 40px;
    background: #ffa024;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .progressBar span {
    background-color: #e6e7e9;
    width: 120px;
    height: 7px;
    position: relative;
    top: 0;
  }
  .progressBar span .progressLength {
    position: absolute;
    top: 0;
    bottom: 0;
    /* width: 60px; */
    background-color: #ffa024;
  }
  .progressBar p:nth-child(3) {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e6e7e9;
  }
}
