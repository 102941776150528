.modalContainer {
  background-color: rgba(61, 56, 51, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 7;
  display: flex;
  align-items: flex-end;
}
.modalContentContainer .modalCloseBtn {
  width: 40px;
  height: 40px;
  background: #f0f1f2;
  border: 1px solid #f9f9fb;
  border-radius: 50px;
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalContentContainer {
  background: #fafafa;
  border: 1px solid #e6fbfa;
  border-radius: 24px 24px 0px 0px;
  padding: 80px 20px;
  width: 100%;
  position: relative;
  z-index: 15;
}
.modalContentContainer h4 {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #181819;
}
.modalContentContainer p:nth-child(2) {
  font-size: 16px;
  line-height: 24px;
  color: #565758;
  margin-bottom: 40px;
}
.modalContentContainer .bottomLink {
  margin-top: 30px;
  font-size: 14px;
  line-height: 24px;
  color: #565758;
}
.modalContentContainer .bottomLink span {
  cursor: pointer;
}
.terms p {
  color: #919294;
  font-size: 14px;
}
.terms p span {
  color: #181819;
}
.terms {
  margin-top: 40px;
}

.modalBottomImage {
  display: none;
  /* border: 1px solid red; */
}
.modalContentContainer div:nth-child(3) {
  margin-bottom: 40px;
}

.timerActive {
  color: #ffdfb6;
}
.timerInactive {
  color: #ffa024;
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
}

/* wide screens */
@media screen and (min-width: 1024px) {
  .modalContainer {
    align-items: center;
    justify-content: center;
  }
  .modalContentContainer {
    border-radius: 24px;
    padding: 80px;
    width: 632px !important;
    margin: auto;
  }

  .modalBottomImage {
    display: inline-flex;
    position: absolute;
    top: 90%;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0 0 24px 24px;
    align-items: flex-end;
  }
  .modalBottomImage img {
    border-radius: 0 0 24px 24px;
  }
  .terms {
    width: 319px;
  }
}
