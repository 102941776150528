@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;

  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Nunito", sans-serif;

  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; */
}

li,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  /* font-family: "Nunito", sans-serif; */
}

h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: "Amaranth", sans-serif;
  font-weight: 700;
  font-style: normal;
}
p,
li {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

::placeholder {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 14px !important;
}

.responsiveContainer {
  width: 100vw;
  max-width: 100%;
  min-width: 100%;
}
/* address_components */
.css-tlfecz-indicatorContainer {
  display: none !important;
}
.css-1wy0on6 {
  display: none !important;
}

.css-26l3qy-menu {
  z-index: 100 !important;
}
.css-yk16xz-control {
  /* width: 416px; */
  background: #ffffff !important;
  border: 1px solid #ebebeb !important;
  border-radius: 8px !important;
  outline: none !important;
}

::placeholder {
  font-family: "Nunito";
  /* font-size: 14px !important; */
}
.css-a6opch-control {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

@media screen and (min-width: 1024px) {
  .responsiveContainer {
    /* max-width: 1440px; */
    /* min-width: 1440px; */
    /* width: 1440px; */
    /* margin: auto; */
    height: 100%;
  }
}
