.walletCard {
  padding: 16px 24px;
  border-radius: 16px;
  box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.walletGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  padding: 40px 20px;
}
.walletTableSection {
  padding: 40px 20px;
}
.walletCardIcon {
  display: flex;
  width: 46px;
  height: 46px;
  padding: 11px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.walletTable {
  box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 16px;
}
.walletTable header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
}
.walletTable header ul {
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: #ebebeb;
  padding: 4px 8px;
  gap: 4px;
  /* overflow: scroll; */
}

.tagItem {
  border-radius: 8px;
  background: #fff;
  padding: 4px 12px;
  box-shadow: 0px 6px 6px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.4);
}

.subHeader {
  background: #f9f9fb;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(145, 146, 148, 1);
}

.subHeader li {
  width: 100%;
}

.walletTableItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
}
.walletTableItem span {
  width: 100%;
  color: rgba(56, 56, 58, 1) !important;
}

#hidden {
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 1200) {
  .walletGrid {
    grid-template-columns: repeat(2, 1fr);
    padding: 80px 60px;
  }
  .walletTableSection {
    padding: 80px 60px;
  }

  #hidden {
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .walletGrid {
    grid-template-columns: repeat(3, 1fr);
    padding: 80px 120px;
  }
  .walletTableSection {
    padding: 80px 120px;
  }

  #hidden {
    display: block;
  }
  .subHeader {
    padding: 20px 24px;
  }
  .walletNavIcon {
    margin-right: 16px;
  }
  .walletResponsive{
    max-width: 1440px;
    margin: 0 auto;
  }
}
