.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  padding: 20px;
}

.navLinksContainer {
  border-radius: 50px;
  z-index: 20;
  border-radius: 48px;
  border-width: 1px;
  border-style: solid;
  /* width: 400px; */
  box-shadow: 4px 4px 16px 0px rgba(3, 3, 3, 0.06);
  padding-right: 5px;
}

.navLinksContainer ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobileOrderNowBtn {
  background-color: #030303;
  border: 1px solid #ffb049;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
  position: relative;
  height: 45px;
  width: 45px;
}

.navSignUpBtn {
  padding: 12px 16px;
  background: #ffdfb6;
  border-radius: 8px;
  overflow: hidden;
  color: #ff9000;
}
.mobileNavContent {
  position: fixed;
  right: 0;
  left: 0;
  top: 10px;
  width: 90%;
  margin: auto;
  padding: 50px 20px;
  z-index: 15;
  overflow-y: auto;
  transform: translateY(-100%); /* Start off-screen to the left */
  -webkit-transform: translateY(-100%); /* WebKit-specific property */
  transition: transform 0.3s ease-in-out;
  -webkit-transition: -webkit-transform 0.3s ease-in-out; /* WebKit-specific property */
  border-radius: 24px;
  border: 0.5px solid var(--Color-Primary-600, #04d9d1);
  background: #032027;

  /* Nav */
  box-shadow: 4px 4px 8px 0px rgba(17, 16, 20, 0.08);
}

.mobileNavContent.active {
  transform: translateX(0); /* Slide in the menu when active */
  -webkit-transform: translateX(0); /* WebKit-specific property */
}

.mobileActiveLink {
  height: 100%;
  width: 150px;
  border-radius: 24px;
  border: 0.5px solid var(--Color-Primary-600, #04d9d1);
  background: #032027;
  box-shadow: 4px 4px 8px 0px rgba(17, 16, 20, 0.08);
  color: #fff;
  padding: 8px 16px;
  text-align: center;
}
.mobileNavDropDown {
  height: 150px;
  width: 150px;
  border-radius: 24px;
  border: 1px solid #04d9d1;
  background: #032027;
  box-shadow: 4px 4px 8px 0px rgba(17, 16, 20, 0.08);
  display: inline-flex;
  padding: 12px !important;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 12px;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  left: 0px;
  right: 60px;
  top: -2px;
}

/* .navLinksContainer .activeLink {
  color: #fff !important;
  background-color: #000;
  border-radius: 16px;
} */
.none-home {
  color: #b1b2b4;
}
.none-others {
  color: #b5cad0;
}
.activeLink-home {
  border-radius: 24px;
  border: 0.5px solid #f9f9fb;
  background: rgba(249, 249, 251, 1);
  box-shadow: 4px 4px 8px 0px rgba(17, 16, 20, 0.08);
  color: #000;
  padding: 8px 16px 8px 8px;
  height: 100%;
}
.activeLink-others {
  border-radius: 24px;
  border: 0.5px solid #e6edef;
  background: #052f3a;
  box-shadow: 4px 4px 8px 0px rgba(17, 16, 20, 0.08);
  color: #f9f9fb;
  padding: 8px 16px 8px 8px;
  height: 100%;
}
.mobileMenuToggle {
  background: #fff;
  padding: 10px;
  height: 48px;
  width: 48px;
  border-radius: 99px;
  color: #000;
  border: 1px solid #000;
}

.homeNavContainer {
  position: fixed;
  margin-top: 10px;
  left: 0;
  right: 0;
  max-width: 100%;
  min-width: 100%;
  z-index: 5;
  transform: translateY(0); /* Default state, visible */
  transition: transform 0.4s ease-in-out; /* Smooth transition */
}
.homeNavContent {
  padding: 10px 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;
  transition: transform 0.4s ease-in-out;
  box-shadow: 4px 4px 8px rgba(17, 16, 20, 0.08);
}

.logoBtn {
  object-fit: cover;
  height: 50px;
  width: 50px;
}
.homeNavContent .leftNavContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 10px; */
}
.homeNavContent .rightNavContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.homeNavContainer .homeNavLocationContainer {
  background-color: #04d9d1;
  border-radius: 16px;
  gap: 10px;
  padding: 8px;
  /* width: 100%; */
}

.homeNavContent .userAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #68e8e3;
  border-radius: 50%;
  cursor: pointer;
}

.homeNavContent .mobileAvatar {
  visibility: visible;
}
.homeNavContent .avatarDropDown {
  padding: 16px 20px;
  position: absolute;
  max-width: 100%;
  min-width: 300px;
  right: 0;
  left: 0;
  top: 70px;
  background: #ffffff;
  border-color: #68e8e3;
  /* box-shadow: 0px 4px 4px 8px rgba(17, 16, 20, 0.08); */
  border-radius: 0 0 16px 16px;
  display: grid;
  gap: 16px 10px;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;
  transition: 0.3s ease-in-out all;
}

.homeNavContent #signInDropDownContainer {
  min-width: 120px;
  max-width: 120px;
}
.homeNavContent .signInDropDown {
  width: 84px;
  margin: auto;
}
.homeNavSignInBtn {
  border-radius: 20px;
  width: 80px;
  /* height: 40px; */
  padding: 10px;
}
.homeNavContent .orderButtonContainer {
  display: none;
}
.homeNavContent .cartIcon {
  cursor: pointer;
}

.signInButton {
  display: none;
}

.avatarDropDown .dropDownName {
  width: 200px;
}
.avatarDropDown .dropDownName span {
  font-size: 14px;
  line-height: 24px;
  color: #565758;
}

.homeNavContent .avatarDropDown .phoneNumber {
  font-size: 12px;
  line-height: 16px;
  color: #565758;
}
.homeNavContent .avatarDropDown .rightSide {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.homeNavContent .avatarDropDown .rightSide .logout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  width: 73px;
  height: 32px;
  background: #fdcdcd;
  border-radius: 8px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #f84e4e;
}
.homeNavIconContainer {
  border: 1.5px solid #04d9d1;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.homeNavSignInBtn {
  height: 40px;
  border-radius: 20px;
}

.newsLetterContainer {
  padding: 20px;
  background: #010101;
  justify-content: center;
  /* text-align: center !important; */
  border-radius: 24px 24px 0 0;
  background-image: url("https://dado-food-store.s3.eu-central-1.amazonaws.com/1710341080639");
}

.newsLetterContainer {
  flex-wrap: wrap;
}

.inputCustom {
  margin-top: 0px;
}
.footerMainContainer {
  padding: 20px;
}

.footerContainer {
  /* background-color: #181819; */
  background: #010101;
  padding: 30px 20px;
  border-radius: 0 0 24px 24px;
}
.footerGridContainer {
  border-top: 0.5px solid #38383a;
  border-bottom: 0.5px solid #38383a;
  padding: 32px 0;
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px 0;
}
.footerGridContainer aside:nth-child(1) {
  border-right: none;
}
.footerGridContainer aside:nth-child(2) {
  display: flex;
  justify-content: center;
}
.storeLinksContainer {
  border-radius: 8px;
  border: 0.5px solid #696a6b;
  background: #181819;
  padding: 16px;
}
.storeLinksTab {
  width: 132px;
  height: 44px;
  border-radius: 50px;
  background: #fff;
  box-shadow: 4px 4px 16px 0px rgba(3, 3, 3, 0.06);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  margin: 0 auto 24px auto;
}
.storeLinksContent {
  padding: 10px;
}
.storeLinksContent button {
  background: inherit;
  border: none;
}
.active-tab {
  border-radius: 105px;
  border: 0.5px solid #04d9d1;
  background: #04d9d1;
  padding: 6px;
}
.footerBottom {
  padding-top: 40px;
}

.locationIcon {
  color: #ffa024 !important;
  /* margin: 0 8px 0 20px; */
}
.arrowDownIcon {
  margin-left: 14px;
}
.addressBtn {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #fff;
}
.notificationBar {
  /* border: 1px solid red; */
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 20px 0 !important;
  flex-direction: column;
  background-color: #04d9d1;
  padding: 20px !important;
  height: 200px;
}
.notificationClockIcon {
  visibility: hidden;
}

.callButton {
  background-color: inherit;
  border: none;
}

/* tablets */
@media screen and (min-width: 700px) and (max-width: 1023px) {
  .newsLetterContainer {
    padding: 20px;
    flex-direction: row;
    justify-content: space-between;
  }
}

/* wide screens */
@media screen and (min-width: 1024px) {
  .orderButtonContainer {
    margin: 0 0 0 auto;
    display: block;
  }
  .mobileNavContainer {
    display: none;
  }
  .homeNavContainer {
    max-width: 1440px;
    min-width: 1440px;
    margin: auto;
  }
  .homeNavContent {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    z-index: 5;
    box-shadow: none;
    padding: 20px 80px;
  }
  .notificationClockIcon {
    visibility: visible;
    margin-right: 8px;
  }

  .homeNavContent .leftNavContainer {
    margin-bottom: 0;
  }
  .homeNavContent .rightNavContainer {
    justify-content: flex-end;
  }
  .homeNavContent .mobileAvatar {
    visibility: hidden;
  }

  .homeNavContent .orderButtonContainer {
    display: block;
    margin-left: 20px;
  }

  .homeNavMobileBtn {
    display: none;
  }

  .signInButton {
    display: block;
  }
  .homeNavContent .cartIcon {
    position: relative;
    cursor: pointer;
    margin: 0 20px 0 30px;
  }
  .newsLetterContainer {
    /* padding: 48px 112px; */
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 768px) {
  .navContainer {
    padding: 20px 40px;
    max-width: 1440px;
    margin: 0 auto;
    /* border-bottom: 0.5px #f0f1f2 solid; */
  }
  .navContainer ul {
    width: auto;
    padding: 5px;
    gap: 40px;
  }
  .navLinksContainer {
    /* padding: 0 12px; */
  }

  .navSignUpBtn,
  .callButton {
    display: block;
  }

  .logoBtn {
    height: 64px;
    width: 64px;
  }

  .homeNavContainer .homeNavLocationContainer {
    padding: 14px;
    border-radius: 20px;
    /* width: 100%; */
  }
  .homeNavContent {
    padding: 20px;
  }
  .homeNavContent .leftNavContainer {
    /* margin-bottom: 20px; */
  }

  .addressBtn {
    font-size: 14px;
  }
  .homeNavIconContainer {
    height: 40px;
    width: 40px;
  }

  .footerMainContainer {
    padding: 80px;
  }

  .footerContainer {
    padding: 20px 40px 40px 40px;
  }
  .footerGridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  .footerGridContainer aside:nth-child(1) {
    border-right: 1px solid #38383a;
  }
  .footerGridContainer aside:nth-child(2) {
    justify-content: flex-end;
  }
  .newsLetterContainer {
    padding: 30px 80px;
    text-align: left;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .notificationBar {
    max-width: 1440px;
    margin: 0 auto;
    flex-direction: row;
  }
}

@media only screen and (min-width: 1800px) {
  .footerContainer,
  .newsLetterContainer {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
}
